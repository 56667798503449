<template>
  <div class="dashboard__container--body" v-if="(userProfile  && userProfile.id)">

    <!-- <div class="dashboard__container--body--col shiftCard">
      <ProfileSkills :userProfile="userProfile" :showtitle="false" />
    </div> -->

    <div class="dashboard__container--body--col shiftCard" v-if="userProfile && !userProfile.interview">
      <ProfileInterview :userProfile="userProfile" :showtitle="false" :lout="true" :h2="false" />
    </div>
    <div class="dashboard__container--body--col shiftCard" v-if="userProfile && userProfile.interview">
      <ProfileInterviewAnswers :userProfile="userProfile" />
    </div>

  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ProfileInterview from '@/components/Profile/ProfileInterview.vue'
import ProfileInterviewAnswers from '@/components/Profile/ProfileInterviewAnswers.vue'
import ProfileSkills from '@/components/Profile/ProfileSkills.vue'

export default {
  name: 'accountInterview',
  computed: {
    ...mapState(['userProfile', 'currentUser']),
  },
   components: {
    Loader,
    ProfileInterview,
    ProfileInterviewAnswers,
    ProfileSkills
  },
}
</script>