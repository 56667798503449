<template>
	<div class="whiteBack mb-5">
    <h1 class="mb-3">Job Placement Questions</h1>

      <div class="mb-5">
        <label for="types">What types of postions are you interested in working?</label>
        <v-select
          label="title" 
          :options="types"
          multiple
          v-model="form.types"
          >
        </v-select>
      </div>
      <div class="mb-5">
        <label for="hours">How many hours are you looking to work each week?</label>
        <v-select
          label="title" 
          :options="hours"
          multiple
          v-model="form.hours"
          >
        </v-select>
      </div>
      <div class="mb-5">
        <label for="experience">Tell us about your work experience:</label>
        <textarea name="experience" id="" cols="30" rows="2" v-model.trim="form.experience"></textarea>
      </div>
      <div class="mb-5">
        <label for="forty">Are you able to lift and move a box that weighs 40lbs?</label>
        <v-select
          label="title" 
          :options="yesNo"
          v-model="form.forty"
          >
        </v-select>
      </div>
      <div class="mb-5">
        <label for="standing">Are you comfortable standing and walking for long periods of time?</label>
        <v-select
          label="title" 
          :options="yesNo"
          v-model="form.standing"
          >
        </v-select>
      </div>
      <div class="mb-5">
        <label for="background">Is there anything we should be aware of when we run your background check?</label>
        <v-select
          label="title" 
          :options="yesNo"
          v-model="form.background"
          >
        </v-select>
        <transition name="fade">
          <div class="mt-3" v-if="form.background == 'Yes'">
            <label for="explain">Please explain:</label>
            <input type="text" placeholder="" v-model.trim="form.explain" id="explain" />
          </div>
        </transition>
      </div>
      <div class="mb-5">
        <label for="passDrugTest">Are you confident that you would pass a drug test?</label>
        <v-select
          label="title" 
          :options="yesNo"
          v-model="form.passDrugTest"
          >
        </v-select>
      </div>
      <div class="mb-5">
        <label for="transportation">Do you have reliable transportation?</label>
        <v-select
          label="title" 
          :options="yesNo"
          v-model="form.transportation"
          >
        </v-select>
      </div>
      <div class="mb-5">
        <label for="nightsWeekends">Are you able to work nights or weekends?</label>
        <v-select
          label="title" 
          :options="yesNoSometimes"
          v-model="form.nightsWeekends"
          >
        </v-select>
      </div>
      <div class="mb-5">
        <label for="anythingElse">Is there anything else we should know about you?</label>
        <textarea name="anythingElse" id="" cols="30" rows="2" v-model.trim="form.anythingElse"></textarea>
      </div>
      <!-- <div class="mb-5">
        <label for="when">When are you able to start?</label>
        <input type="text" placeholder="" v-model.trim="form.when" id="when" />
      </div> -->
      <!-- <div class="mb-5">
        <label for="notes">Notes from Interviewer:</label>
        <textarea name="notes" id="" cols="30" rows="2" v-model.trim="form.notes"></textarea>
      </div> -->
      <div>
        <button class="btn btn__primary btn__large mt-2" @click="addInterview()">Submit
          <transition name="fade">
            <span class="ml-2" v-if="performingRequest">
            <i class="fa fa-spinner fa-spin"></i>
            </span>
          </transition>
        </button>
      </div>

	</div>
</template>

<script>
import { mapState } from 'vuex'
const fb = require('../../firebaseConfig.js')

export default {
  props: ['userProfile'],
  data: () => ({
    performingRequest: false,
    types: ['Food and Beverage', 'Janitorial', 'Guest Services', 'Housekeeping', 'Clerical/Office', 'Warehouse', 'Construction/Trades', 'Other'],
    hours: ['0-10', '10-20', '20-30', '30-40'],
    yesNo: ['Yes', 'No'],
    yesNoSometimes: ['Yes', 'No', 'Sometimes'],
    form: {},
  }),
  computed: {
    ...mapState([ 'currentUser']),
  },
  methods: {
    // updateProfile(){
    // 	let userProfile = this.userProfile
    // 	this.$store.dispatch('updateUser', userProfile)
    // },
    async addInterview() {
      this.performingRequest = true
      console.log(this.userProfile)
      let form = this.form
      await fb.usersCollection.doc(this.userProfile.id).update({
        interview: form,
        interviewed: fb.firestore.FieldValue.serverTimestamp()
      })
      setTimeout(() => {
        this.performingRequest = false
      }, 2000)
    }
  }
}
</script>